import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../views/Layout'
import SEO from '../../views/Seo'
import { parseSeo } from '../../helpers/seo'
import { Text } from '../../components/Text'
import { IndentWrapper } from '../../blocks/IndentWrapper'
import { Heading } from '../../components/Heading'
import { Divider } from '../../components/Divider'
import { Container } from '../../components/Container'
import { MapHeader } from '../../components/MapHeader'

import { Breadcrumbs } from '../../components/Breadcrumbs'
import { COLORS, SPACING } from '../../helpers/constants'
import { CityDealersSection } from '../../blocks/CityDealersSection'
import { CitySummarySection } from '../../blocks/CitySummarySection'
import { PageContent } from '../../components/PageContent'
import { Content } from '../../components/Content'

export default ({ data, pageContext }) => {
  const { dealerCountry } = data.wordpress
  const { dealers } = pageContext.data

  const { name, description, seo } = dealerCountry

  const cities = [
    ...new Set(
      dealers.map(dealer =>
        dealer.acfAttributes.dealerListCity
          ? dealer.acfAttributes.dealerListCity
          : dealer.acfAttributes.dealerCity
      )
    ),
  ].sort()

  const firstLetter = [
    ...new Set(
      cities
        .filter(city => city !== null)
        .map(city => city.charAt(0).toUpperCase())
        .sort()
    ),
  ]

  let arr = []
  firstLetter.forEach(letter => {
    for (var i = 0; i < cities.length; i++) {
      if (cities[i] === undefined || cities[i] === null) break
      if (cities[i].charAt(0) === letter) {
        arr.push(cities[i])
        break
      }
    }
  })

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <MapHeader atList />
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} />
      <PageContent>
        <Container>
          <IndentWrapper col={3}>
            <Content>
              <Heading type={'h1'} size={1} color={COLORS.PRIMARY_RED}>
                {name}
              </Heading>
              {description && <Text columns={2}>{description}</Text>}
            </Content>
            <Divider size={SPACING.MEDIUM} />
          </IndentWrapper>
          <Divider line={COLORS.ADAPTED_LIGHT_BLUE} />

          <CitySummarySection letters={firstLetter} />

          {cities.map((city, key) => (
            <CityDealersSection
              key={key}
              dealers={dealers}
              city={city}
              cityId={arr}
            />
          ))}
        </Container>
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    wordpress {
      dealerCountry(id: $id) {
        ...Single_Dealer_Country_Category
      }
    }
  }
`
