import styled from 'styled-components'
import { spacing, colors, mq } from '../../styles/utilities/variables'
import StyledText from '../../components/Text/style'

const StyledCityDealersSection = styled.div`
  position: relative;
  padding-bottom: ${spacing.large};

  @media ${mq.mobile} {
    padding-bottom: ${spacing.medium};
  }

  & ${StyledText} {
    text-transform: uppercase;
    color: ${colors.primaryDarkBlue};
  }

  & a {
    color: ${colors.black};
  }
`

StyledCityDealersSection.Anchor = styled.div`
  position: absolute;
  width: 0;
  height: 0;
`

export default StyledCityDealersSection
