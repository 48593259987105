/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import StyledMapLocationCard from './style'
import { InfoIcon, EcoRidePlus } from '../Svg'
import { DEALER_TYPES } from '../../helpers/constants'
import { useTranslation } from '../../hooks/useTranslation'

const MapLocationCard = props => {
  const {
    title,
    types,
    phone,
    address,
    city,
    distance,
    link,
    priority = false,
    zip,
  } = props

  const getTranslation = useTranslation()

  const formattedZip =
    zip && zip.length === 5
      ? zip.replace(/(\d{3})/g, '$1 ').replace(/(^\s+|\s+$)/, '')
      : ''

  const handleScrollToTop = e => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const typesString = types
    ? types.reduce((acc, type) => {
        if (type === DEALER_TYPES.STORE) {
          return acc
            ? acc + ' + ' + getTranslation('shopText')
            : getTranslation('shopText')
        }

        if (type === DEALER_TYPES.WORKSHOP) {
          return acc
            ? acc + ' + ' + getTranslation('workshopText')
            : getTranslation('workshopText')
        }

        return acc
      }, '')
    : 'No type set'

  return (
    <StyledMapLocationCard onClick={handleScrollToTop}>
      <StyledMapLocationCard.Wrapper>
        {priority && <EcoRidePlus />}
        <StyledMapLocationCard.Title>{title}</StyledMapLocationCard.Title>
        {link && (
          <StyledMapLocationCard.Link to={link}>
            <InfoIcon />
          </StyledMapLocationCard.Link>
        )}
      </StyledMapLocationCard.Wrapper>
      <StyledMapLocationCard.DealerType>
        {typesString}
      </StyledMapLocationCard.DealerType>
      {phone && (
        <StyledMapLocationCard.Phone>{phone}</StyledMapLocationCard.Phone>
      )}
      <p>
        {address}
        {city && (
          <>
            ,{' '}
            <span>
              {formattedZip} {city}
            </span>
          </>
        )}
      </p>
      {distance && <p>{distance} KM</p>}
    </StyledMapLocationCard>
  )
}

MapLocationCard.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  distance: PropTypes.any,
  link: PropTypes.string,
  priority: PropTypes.bool,
}

export { MapLocationCard }
