import React, { useEffect } from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import PropTypes from 'prop-types'
import StyledCitySummarySection from './style'
import { Row } from '../../components/Row'
import { Text } from '../../components/Text'
import { Select } from '../../components/Select'
import { useResponsive } from '../../hooks/useResponsive'
import { useHasMounted } from '../../hooks/useHasMounted'
import { useTranslation } from '../../hooks/useTranslation'

const CitySummarySection = ({ letters }) => {
  const { windowSize } = useResponsive()
  const hasMounted = useHasMounted()
  const getTranslation = useTranslation()

  const scrollToSection = anchorId => {
    if (hasMounted) {
      let top = document.getElementById(anchorId).offsetTop
      let offset = windowSize.width >= 1024 ? 180 : 130
      window.scrollTo({
        top: top + offset,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    if (hasMounted) smoothscroll.polyfill()
  }, [hasMounted])

  return (
    <StyledCitySummarySection>
      {windowSize.width >= 980 && (
        <Row align={'flex-start'} justify={'flex-start'}>
          <Text>{getTranslation('goToCityText')}:</Text>
          {letters.map((item, key) => (
            <StyledCitySummarySection.ScrollButton
              key={key}
              onClick={() => {
                scrollToSection(item)
              }}
            >
              {item}
            </StyledCitySummarySection.ScrollButton>
          ))}
        </Row>
      )}
      {windowSize.width <= 979 && (
        <Select
          items={letters}
          //initialValue={text}
          changeHandler={e => scrollToSection(e.target.value)}
        />
      )}
    </StyledCitySummarySection>
  )
}

CitySummarySection.propTypes = {
  letters: PropTypes.array,
}

export { CitySummarySection }
