import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import StyledCityDealersSection from './style'
import { COLORS } from '../../helpers/constants'
import { MapLocationCard } from '../../components/MapLocationCard'
import { Divider } from '../../components/Divider'
import { Text } from '../../components/Text'
import { Grid } from '../../components/Grid'
import { DEALER_TYPES } from '../../helpers/constants'

const CityDealersSection = props => {
  const { dealers, city, cityId } = props

  const municipality = city ? city.replace('-området', '') : null //Fast fix, need to be properly fixed

  const id =
    cityId.find(id => id === city) && city ? city.replace('-området', '') : null //Fast fix, need to be properly fixed

  if (!city) return null
  return (
    <StyledCityDealersSection
      id={id === municipality ? `${id.charAt(0).toUpperCase()}` : null}
    >
      <Divider line={COLORS.ADAPTED_LIGHT_BLUE} />
      <Text>{municipality}</Text>
      <Grid col={4} tabletCol={2} mobileCol={1}>
        {dealers.map((dealer, index) => {
          const {
            title,
            uri,
            acfAttributes: {
              dealerPhone,
              dealerAddress,
              dealerListCity,
              dealerCity,
              dealerServiceCenter,
              dealerShopCenter,
            },
          } = dealer
          const types = [
            dealerServiceCenter ? DEALER_TYPES.WORKSHOP : null,
            dealerShopCenter ? DEALER_TYPES.STORE : null,
          ]

          return (
            city === dealerListCity && (
              <Link key={index} to={uri}>
                <MapLocationCard
                  title={title}
                  types={types}
                  phone={dealerPhone}
                  address={dealerAddress}
                  city={
                    dealerListCity
                      ? dealerListCity.replace('-området', '')
                      : dealerCity.replace('-området', '')
                  } /* Fast fix, need to be properly fixed  */
                  distance={null}
                  link={null}
                />
              </Link>
            )
          )
        })}
      </Grid>
    </StyledCityDealersSection>
  )
}

CityDealersSection.propTypes = {
  dealers: PropTypes.array,
  cityId: PropTypes.array,
  city: PropTypes.string,
}

export { CityDealersSection }
