import styled from 'styled-components'

const StyledSelect = styled.select`
  width: 100%;
  background: transparent;
  border: none;
  text-transform: uppercase;
`

export default StyledSelect
