import React from 'react'
import PropTypes from 'prop-types'
import StyledMapHeader from './style'
import { Heading } from '../Heading'
import { TextLink } from '../TextLink'
import { getUrl } from '../../helpers/url'
import { useTranslation } from '../../hooks/useTranslation'

const MapHeader = props => {
  const { link } = props
  const getTranslation = useTranslation()

  return (
    <StyledMapHeader {...props}>
      <StyledMapHeader.Content>
        <Heading size={3} type={'h3'}>
          {getTranslation('shopAndWorkshopText')}
        </Heading>

        {link && <TextLink to={getUrl(link.url)} text={link.title} underline />}
      </StyledMapHeader.Content>
    </StyledMapHeader>
  )
}

MapHeader.propTypes = {
  link: PropTypes.object,
}

export { MapHeader }
