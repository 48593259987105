import styled from 'styled-components'
import StyledText from '../../components/Text/style'
import { spacing, colors } from '../../styles/utilities/variables'

const StyledCitySummarySection = styled.div`
  & ${StyledText} {
    margin-right: ${spacing.xSmall};
    text-transform: uppercase;
    color: ${colors.primaryDarkBlue};
  }
`

StyledCitySummarySection.ScrollButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  padding: 0 ${spacing.xxxSmall};
  color: ${colors.primaryDarkBlue};

  &:active,
  &:focus {
    outline: none;
  }
`

export default StyledCitySummarySection
