import React from 'react'
import PropTypes from 'prop-types'
import StyledSelect from './style'

const Select = ({ items, initialValue, changeHandler }) => {
  return (
    <StyledSelect onChange={changeHandler ? changeHandler : null}>
      <option value={''}>{initialValue}</option>
      {items.map((item, key) => {
        return (
          <option key={key} value={item}>
            {item}
          </option>
        )
      })}
    </StyledSelect>
  )
}

Select.propTypes = {
  items: PropTypes.array,
  initialValue: PropTypes.array,
  changeHandler: PropTypes.func,
}

export { Select }
