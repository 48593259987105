import { Link } from 'gatsby'
import styled from 'styled-components'
import {
  spacing,
  typography,
  mq,
  colors,
  cssEasings,
} from '../../styles/utilities/variables'

const StyledMapLocationCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  @media ${mq.tablet} {
    padding: 10px;
  }

  p {
    font-size: ${typography.sizes.xSmall} !important;
    margin-bottom: ${spacing.xxSmall} !important;

    &:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & span {
        margin-top: 0.4rem;
      }
    }
  }
`

StyledMapLocationCard.Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;

  > svg {
    position: absolute;
    left: -20px;
    top: -2px;
  }
`

StyledMapLocationCard.Title = styled.p`
  color: ${colors.primaryDarkBlue};
  font-size: ${typography.sizes.small};
  margin-bottom: ${spacing.xxSmall};
  text-transform: uppercase;
  font-weight: 500;
  text-align: left;
`

StyledMapLocationCard.Link = styled(Link)`
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
  margin-left: 10px;

  @media ${mq.tablet} {
    margin-top: -1px;
  }

  transition: transform 0.3s ${cssEasings.easeOutCubic};
  &:hover {
    transform: scale(1.4);
  }
`

StyledMapLocationCard.DealerType = styled.p`
  text-transform: uppercase;
`

StyledMapLocationCard.Phone = styled.p`
  width: 100%;
  text-align: left;
`

export default StyledMapLocationCard
