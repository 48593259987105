import styled from 'styled-components'
import { spacing, colors, mq } from '../../styles/utilities/variables'
import StyledTextLink from '../TextLink/style'

const StyledMapHeader = styled.div`
  background: ${colors.secondaryLightBlue};
  padding: 0 ${spacing.medium};
`

StyledMapHeader.Content = styled.div`
  padding: ${spacing.base} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & ${StyledTextLink} {
    @media ${mq.mobile} {
      display: none;
    }
  }
`

export default StyledMapHeader
